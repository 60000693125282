import Vue from 'vue'
// axios
import axios from 'axios'
import NProgress from 'nprogress';
import router from '@/router'
import store from '@/store'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://smartfarm.itani.id/api/', // prod te
   baseURL: 'https://api.smartfarm.itani.id/v1/', // prod te
  //baseURL: 'http://localhost:8001/v1/', // dev
  // baseURL: 'http://192.168.100.29/api/', // lokal
 
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  //  timeout: 550, // turn on this section if want to test api performance 
})

Vue.prototype.$http = axiosIns

// setup loading by nprogress
axiosIns.interceptors.request.use(config => {
    if(!config.url.includes('notifications')){
      NProgress.start()
    }
    return config
});

axiosIns.interceptors.response.use(
    response => {
        NProgress.done()
        return Promise.resolve(response)
    },
    async (error) => {
      if(error.response.status === 401){
        await store.dispatch('auth/clearToken').then(() => {
          router.push({name: 'auth-login'})
        })
      }
      NProgress.done() 
      return Promise.reject(error)
    }
);

export default axiosIns
