import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress';


//acl
import { canNavigate } from '@/libs/acl/routeProtection'

// Routes
import pages from './routes/pages'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// setup nprogress beforeResolve route flow
router.beforeResolve((to, from, next) => {
  if (to.name) {
      NProgress.start()
  }
  next()
})

router.beforeEach((to, _, next) => {
  const isLoggedIn =  store.getters["auth/authenticated"]

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn)  return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  
  if (to.meta.redirectIfLoggedIn && store.getters["auth/authenticated"]) {    
    next('/dashboard')
  }

  return next()
})

// For splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  // if route change is completed
  NProgress.done()
})

export default router
