import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
// test firebase
import { firebaseApp } from "@/libs/firebase"

Vue.prototype.$firebaseApp = firebaseApp;

if('serviceWorker' in navigator){
  navigator.serviceWorker.register(`firebase-messaging-sw.js`, {
    scope: "firebase-cloud-messaging-push-scope",
  })
    .then(reg => {
      console.log(`Service Worker Registration (Scope: ${reg.scope})`);
    })
    .catch(error => {
      const msg = `Service Worker Error (${error})`;
      console.info(msg);
    });
  // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
  console.warn('Service Worker not available');
} else {
  console.log('Service worker not available');
}

import 'nprogress/nprogress.css'
import '@core/scss/vue/libs/nprogress.scss'

import moment from 'moment'

Vue.prototype.moment = moment

moment.locale('id');

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

store.dispatch("auth/me").then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
