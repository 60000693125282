import axios from "@axios"
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: null,
    userRoles: null,
    userPermissions: null,
    token: null,
    authHeader: null,
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    userRoles(state) {
      return state.userRoles;
    },
    userPermissions(state) {
      return state.userPermissions;
    },
    token(state) {
      if (localStorage.getItem("accessToken")) {
        return localStorage.getItem("accessToken");
      }

      return state.token;
    },
    authHeader(state) {
      return state.authHeader;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      if(value !== null){
        state.user = value;
        localStorage.setItem('userData', JSON.stringify(value))
      }
    },
    SET_USER_ROLES(state, value) {
      state.userRoles = value;
    },
    SET_USER_PERMISSIONS(state, value) {
      state.userPermissions = value;
    },
    SET_TOKEN(state, value) {
      state.token = value
      useJwt.setToken(value)
    },
    SET_AUTH_HEADER(state, value) {
      if (localStorage.getItem("accessToken")) {
        state.authHeader = {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        };
      } else {
        state.authHeader = {
          Authorization: "Bearer " + value,
        };
      }
    },
  },
  actions: {
    async login({commit, getters}, credential) {
      return new Promise((resolve, reject) => {
        axios.post("auth/login", credential)
        .then((response) => {
          commit("SET_TOKEN", response.data.accessToken);
          const token = getters.token;

          if (token !== null) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          }
          
          commit("SET_USER", response.data.userData);
          commit("SET_AUTHENTICATED", true);
          resolve(response.data.userData)
        }).catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            // console.info('localStorage cleared successfully!')
          }
          commit("SET_AUTHENTICATED", false);
          commit("SET_USER", null);
          reject(error)
        })
        
      })
    },

    async register({commit, getters}, credential) {
      let response = await axios.post("api/register", credential);
      commit("SET_TOKEN", response.data.data.access_token);

      try {
        const token = getters.token;

        if (token !== null) {
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }

        let response = await axios.get("api/me");

        commit("SET_USER", response.data);
        let userRoles = response.data.data.roles;

        userRoles.forEach((data) => {
          if (data.name === "mentor") {
            commit("SET_IS_MENTOR", true);
          }
          if (data.name === "mentee") {
            commit("SET_IS_MENTEE", true);
          }
          if (data.name === "admin") {
            commit("SET_IS_ADMIN", true);
          }
        });

        commit("SET_USER_ROLES", response.data.data.roles);
        commit("SET_USER_PERMISSIONS", response.data.data.permissions);
        commit("SET_AUTHENTICATED", true);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
        }
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", null);
        commit("SET_USER_ROLES", null);
        commit("SET_USER_PERMISSIONS", null);
        commit("SET_IS_MENTOR", false);
        commit("SET_IS_MENTEE", false);
        commit("SET_IS_ADMIN", false);
      }
    },

    async logout({commit}) {
      return new Promise ((resolve, reject) => {
        axios.post("/auth/logout")
        .then((response) => {
          commit("SET_TOKEN", null);
          commit("SET_AUTHENTICATED", false);
          commit("SET_USER", null);
          localStorage.clear();
          resolve(response)
        })
        .catch((error) => {
          commit("SET_AUTHENTICATED", false);
          commit("SET_USER", null);
          if (error.response.status === 401) {
            localStorage.clear();
          }     
          reject(error)
        });
        
      })
    },
    clearToken({commit}) {
      commit("SET_TOKEN", null);
      commit("SET_AUTHENTICATED", false);
      commit("SET_USER", null);
      localStorage.clear();
    },
    async me({commit, getters}) {
      axios.get("auth/me")
      .then((response) => {
        commit("SET_USER", response.data.userData);
        commit("SET_AUTHENTICATED", true);
      }).catch( (error) => {
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", null);
        if (error.response.status === 401) {
          localStorage.clear();
          // console.info('localStorage cleared successfully from store me!')
        }
      })
    },
    async changeUser({commit}, user) {
      commit("SET_USER", user);
    },
  },
};
