export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard SmartFarm',
      breadcrumb: [
        {
          text: 'Dashboard SmartFarm',
          active: true,
        },
      ],
      resource: 'dashboard',
      action: 'view',
    },
  },
  {
    path: '/goats',
    name: 'goats',
    component: () => import('@/views/pages/goats/GoatsList.vue'),
    meta: {
      pageTitle: 'Goats',
      breadcrumb: [
        {
          text: 'Goats',
          active: true,
        },
      ],
      resource: 'animal',
      action: 'view',
    },
  },
  {
    path: '/goats/:id',
    name: 'goatsDetail',
    component: () => import('@/views/pages/goats/detail/GoatDetail.vue'),
    meta: {
      pageTitle: 'Goats',
      breadcrumb: [
        {
          text: 'Goats',
          active: true,
        },
      ],
      resource: 'animal',
      action: 'view',
    },
  },
  {
    path: '/monitoring', // testing detail page goat
    name: 'monitoring',
    component: () => import('@/views/pages/raw_milks/detail/GoatMilkedDetail.vue'),
    meta: {
      pageTitle: 'Goats',
      breadcrumb: [
        {
          text: 'Goats',
          active: true,
        },
      ],
    },
  },
  {
    path: '/raw-milks',
    name: 'rawMilks',
    component: () => import('@/views/pages/raw_milks/RawMilksList.vue'),
    meta: {
      pageTitle: 'Perolehan Susu Mentah',
      breadcrumb: [
        {
          text: 'Perolehan Susu Mentah',
          active: true,
        },
      ],
      resource: 'raw_milk',
      action: 'view',
    },
  },
  {
    path: '/raw-milks-stock/history',
    name: 'rawMilksStockHistory',
    component: () => import('@/views/pages/raw_milks/RawMilksList.vue'),
    meta: {
      pageTitle: 'Perolehan Susu Mentah',
      breadcrumb: [
        {
          text: 'Perolehan Susu Mentah',
          active: true,
        },
      ],
      resource: 'raw_milk',
      action: 'view',
    },
  },
  {
    path: '/raw-milks/:id',
    name: 'rawMilksDetail',
    component: () => import('@/views/pages/raw_milks/detail/GoatMilkedDetail.vue'),
    meta: {
      pageTitle: 'Detail Perolehan Susu',
      breadcrumb: [
        {
          text: 'Detail Perolehan Susu',
          active: true,
        },
      ],
      resource: 'raw_milk',
      action: 'view',
    },
  },
  {
    path: '/milk-products',
    name: 'milkProducts',
    component: () => import('@/views/pages/milk_productions/MilkProductsList.vue'),
    meta: {
      pageTitle: 'Produk SmartFarm',
      breadcrumb: [
        {
          text: 'Produk SmartFarm',
          active: true,
        },
      ],
      resource: 'milk_product',
      action: 'view',
    },
  },
  {
    path: '/milk-products/:id',
    name: 'milkProductDetail',
    component: () => import('@/views/pages/milk_productions/detail/MilkProduct.vue'),
    meta: {
      pageTitle: 'Detail Produk',
      breadcrumb: [
        {
          text: 'Detail Produk',
          active: true,
        },
      ],
      resource: 'milk_product',
      action: 'view',
    },
  },
  {
    path: '/master/product-categories',
    name: 'master-product-categories',
    component: () => import('@/views/pages/master/product-category/ProductCategoryList.vue'),
    meta: {
      pageTitle: 'Jenis Produk SmartFarm',
      breadcrumb: [
        {
          text: 'Jenis Produk SmartFarm',
          active: true,
        },
      ],
      resource: 'master_milk_product',
      action: 'view',
    },
  },
  {
    path: '/master/users',
    name: 'master-users',
    component: () => import('@/views/pages/master/users/UserList.vue'),
    meta: {
      pageTitle: 'Data Pengguna',
      breadcrumb: [
        {
          text: 'Data Pengguna',
          active: true,
        },
      ],
      resource: 'master_milk_product',
      action: 'view',
    },
  },
  {
    path: '/master/pens',
    name: 'masterPens',
    component: () => import('@/views/pages/master/pen/PenList.vue'),
    meta: {
      pageTitle: 'Kandang',
      breadcrumb: [
        {
          text: 'Kandang',
          active: true,
        },
      ],
      resource: 'master_milk_product',
      action: 'view',
    },
  },
  {
    path: '/master/kandang',
    name: 'master-kandang',
    component: () => import('@/views/pages/master/kandang/index.vue'),
    meta: {
      pageTitle: 'Kandang SmartFarm',
      breadcrumb: [
        {
          text: 'kandang SmartFarm',
          active: true,
        },
      ],
      resource: 'master_milk_product',
      action: 'view',
    },
  },
  {
    path: "/master/animal-status",
    name: "master-animal-status",
    component: () =>
        import("@/views/pages/master/animal-status/AnimalStatusList.vue"),
    meta: {
        pageTitle: "Status Hewan SmartFarm",
        breadcrumb: [
            {
                text: "Status Hewan SmartFarm",
                active: true,
            },
        ],
        resource: "master_milk_product",
        action: "view",
    },
  },
  {
      path: "/master/animal-status-outs",
      name: "master-animal-status-outs",
      component: () =>
          import(
              "@/views/pages/master/animal-status-outs/AnimalStatusOutsList.vue"
          ),
      meta: {
          pageTitle: "Status Keaktifan Hewan SmartFarm",
          breadcrumb: [
              {
                  text: "Status Keaktifan Hewan SmartFarm",
                  active: true,
              },
          ],
          resource: "master_milk_product",
          action: "view",
      },
  },
  {
    path: "/master/device",
    name: "master-device",
    component: () => import("@/views/pages/master/device/DeviceList.vue"),
    meta: {
        pageTitle: "Device SmartFarm",
        breadcrumb: [
            {
                text: "Device SmartFarm",
                active: true,
            },
        ],
        resource: "master_milk_product",
        action: "view",
    },
  },
  {
    path: '/breeding/matings',
    name: 'matings',
    component: () => import('@/views/pages/breeding/animal_mating/AnimalMatingList.vue'),
    meta: {
      pageTitle: 'Pencatatan Perkawinan Kambing',
      breadcrumb: [
        {
          text: 'Pencatatan Perkawinan Kambing',
          active: true,
        },
      ],
      resource: 'animal_mating',
      action: 'view',
    },
  },
  {
    path: '/breeding/matings/create',
    name: 'matings/create',
    component: () => import('@/views/pages/breeding/animal_mating/AnimalMatingList.vue'),
    meta: {
      pageTitle: 'Catat Perkawinan Kambing',
      breadcrumb: [
        {
          text: 'Catat Perkawinan Kambing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/breeding/pregnant',
    name: 'pregnant',
    component: () => import('@/views/pages/breeding/animal_pregnant/AnimalPregnantList.vue'),
    meta: {
      resource: 'animal_mating',
      action: 'view',
      pageTitle: 'Pencatatan Kambing Bunting',
      breadcrumb: [
        {
          text: 'Pencatatan Kambing Bunting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/breeding/give-birth',
    name: 'giveBirth',
    component: () => import('@/views/pages/breeding/animal_give_birth/AnimalGiveBirthList.vue'),
    meta: {
      resource: 'give_birth',
      action: 'view',
      pageTitle: 'Pencatatan Kambing Beranak',
      breadcrumb: [
        {
          text: 'Pencatatan Kambing Beranak',
          active: true,
        },
      ],
    },
  },
  {
    path: '/breeding/give-birth/:id',
    name: 'giveBirthDetail',
    component: () => import('@/views/pages/breeding/animal_give_birth/detail/AnimalGiveBirthDetail.vue'),
    meta: {
      resource: 'give_birth',
      action: 'view',
      pageTitle: 'Detail Kambing Beranak',
      breadcrumb: [
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/exports',
    name: 'exports-page',
    component: () => import('@/views/pages/exports/ExportsPage.vue'),
    meta: {
      pageTitle: 'Ekspor Data',
      breadcrumb: [
        {
          text: 'Data SmartFarm',
          active: true,
        },
      ],
      resource: 'data',
      action: 'export',
    },
  },
  {
    path: '/license/app',
    name: 'app-license',
    component: () => import('@/views/AppLicense.vue'),
    meta: {
      pageTitle: 'Info Aplikasi',
      breadcrumb: [
        {
          text: 'Lisensi Aplikasi',
          active: true,
        },
      ],
      resource: 'data',
      action: 'export',
    },
  },
  {
    path: '/license/devices',
    name: 'devices-license',
    component: () => import('@/views/DevicesLicense.vue'),
    meta: {
      pageTitle: 'Info Aplikasi',
      breadcrumb: [
        {
          text: 'Tentang Perangkat',
          active: true,
        },
      ],
      resource: 'data',
      action: 'export',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/pages/notifications/Notification.vue'),
    meta: {
      pageTitle: 'Info Aplikasi',
      breadcrumb: [
        {
          text: 'Tentang Perangkat',
          active: true,
        },
      ],
      resource: 'data',
      action: 'export',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

]
